<template>
<Hero :content="hero"/>
 <section-main :data="how.header" class="hosting">
     <div class="hosting__text">
        <p>
            Deploying your website is generally easy because they are included inside the solution you are using to develop your website. 
            Creating a website today is accessible thanks to a lot of  tools that enable anyone to create quickly a beautiful website without any previous knowledge. 
        </p> 
        <p>
            However every seems nice at the beginning, it can, with time become a nightmare. You are dependant on the hosting service, if it fails, you fail with them. If the price rises, you are stuck 
            with them as long as you are afraid to move to another platform.

        </p>
        <p> 
            To prevent that, we build for you a strong hosting foundation for your web applications that removes all the dependance on other services. 
            This method will bring you the following advantages !
        </p>    
     </div>
   

    <div class="hosting__why">
        <item-subject v-for="item of how.data" :data="item" class="hosting__why--item"></item-subject>
    </div>
 </section-main>

 <section-main :data="services.header">
     <div class="serv">
         <div v-for="s of services.data" class="serv__item">
              <div class="serv__logo" :class="s.color ?  'serv__logo--'+s.color :''">
                    <Svg :icon="s.logo" className="serv__logo--svg" size="4"/>
                </div>
                <h3>
                    {{s.title}}
                </h3>
                <div v-for="i of s.content" class="serv__section">
                    <h4>
                        {{i.title}}
                    </h4>
                    <p v-html="i.text">
                    </p>
                </div>
                <button class="btn btn--blue serv__cta">
                    Get a free quote
                </button>
         </div>
        
     </div>
 </section-main>

</template>

<script>
import Hero from '@/components/Hero';
import SectionMain from '@/components/utils/SectionMain'
import ItemSubject from '@/components/item-subject.vue';


import Svg from '@/components/utils/Svg';

export default {
    components:{SectionMain, Hero, ItemSubject, Svg},
    setup(){

        const hero={
            background: false,
            title:"<strong>Professional database and website hosting</strong>",
            subtitle:"We automate your backups and configure your web servers and databases for you such as you don't have issues anymore.",
            deco:[
                {
                    svg: "server",
                },
                {
                    svg:"docker"
                },
                {
                    svg:"database"
                }
            ],
            cta:[
                {
                    target:"",
                    label:""
                }
            ],
            scroll:{
                color:'blue',
                target:'why-hosting'
            }
        }

        const how = {
            header:{
                title:"Why a professionnal hosting setup is important ?",
                subtitle:"A powerful foundation of your digital assets is key to run your business properly !",
                color:'white',
                id:'why-hosting'
            },
            data:[
                {
                    icon:"independance",
                    title:"Independance",
                    text:"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Debitis asperiores optio beatae, maxime, dolore officia et labore consectetur sequi ut deleniti voluptatem quasi a totam fugiat natus. Vel a libero totam qui exercitationem, rem, ea, sit illo corporis fugiat similique labore quod dolorum autem optio officia sint commodi culpa? Vero.",
                    color:"blue"
                },
                  {
                    icon:"performance",
                    title:"Performances",
                    text:"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Debitis asperiores optio beatae, maxime, dolore officia et labore consectetur sequi ut deleniti voluptatem quasi a totam fugiat natus. Vel a libero totam qui exercitationem, rem, ea, sit illo corporis fugiat similique labore quod dolorum autem optio officia sint commodi culpa? Vero.",
                    color:"blue-dark"
                },
                  {
                    icon:"maintenance",
                    title:"Maintenance free",
                    text:"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Debitis asperiores optio beatae, maxime, dolore officia et labore consectetur sequi ut deleniti voluptatem quasi a totam fugiat natus. Vel a libero totam qui exercitationem, rem, ea, sit illo corporis fugiat similique labore quod dolorum autem optio officia sint commodi culpa? Vero.",
                    color:"blue-grey"
                }
            ]
        }

        const services = {
            header:{
                title:"Our services",
                subtitle:"We propose different offers to improve your server side development",
                color:'blue-light',
                id:'services'
            },
            data:[
                {
                    title:"Build the hosting foundation of your application/website",
                    logo:"docker",
                    content:[
                        {
                            title:"For who ?",
                            text:"Any business that wants a reliable and easy-to-manage website that doesn’t depend on a specific provider."
                        },
                        {
                            title:"Technology",
                            text:`We use an open-source platform, called Docker, that helps to create virutal servers for your application. Thanks to a simple script, we will be able to tell the machine what specific programs needs to run in the background to support your application on any server that supports Docker.
                            <br><br>
                            In other terms, you will be able to deploy your application in a few seconds on any hosting platform without having to worry about compatibilty, versioning, updates, ...
                            <br><br>
                            Howerver this has an inital cost, it will save you hours in the future of maintenance and services.`
                        },
                        {
                            title:"Pricing",
                            text:"Price vary in function of the complexity of the application such as : front-end and back-end configuration, databases, backups, reverse proxy, ... and the desired workflow in order to update your website."
                        }
                    ]
                },
                 {
                    title:"Shared private hosting",
                    logo:"server",
                    color:"blue-dark",
                    content:[
                        {
                            title:"For who ?",
                            text:"Anyone that wants to entrust the hosting of its website/application "
                        },
                        {
                            title:"Technology",
                            text:`We have setup a network of powerful servers around the world which hosts our websites and applications. Because those servers are private, we can report exactly what they are doing and provide the right amount of calculation power to satisfy all of our applications.`
                        },
                        {
                            title:"Pricing",
                            text:"Price vary in function of the traffic on your website and the complexity of the requests asked by your application. Hosting small but important websites with us will make you save money and give instant website access to your customers."
                        }
                    ]
                }
            ]
        }

        return{
            hero,
            how,
            services
        }
    }

}
</script>

<style lang="scss">
@import '@/style/main';

.hosting{

    &__text{
        margin-bottom: 2rem;
         & p {
            font-size: 1.6rem;
            &:not(:last-child){
                margin-bottom: .8rem;
            }
        }
    }
   

    &__why{
        display: flex;
        justify-content: space-between;
        width: 100%;

        &--item{
            flex: 0 0 31%;
            width: 100%;
        }
    }
}

.serv{
    display: flex;
    flex-direction: column;
    width: 100%;
    
    &__item{
        background-color: $color-white;
        box-shadow: $shadow;
        
        border:1px solid $color-blue-main;

        position:relative;
        padding: 4rem;
        &:not(:last-child){
            margin-bottom: 8rem;
        }


        & h3{
            font-size: 2.4rem;
            text-transform: uppercase;
            letter-spacing: .1rem;
            margin-bottom: 2rem;
        }

        
    }

    &__section{
        & h4{
            font-size: 1.6rem;
            text-transform: uppercase;
            color:$color-blue-main;
            font-weight: 700;
            margin-bottom: .6rem;
        }
            
        margin-bottom: 1.5rem;
    }

    &__cta{
        margin-top: 2rem;
    }

    &__logo{
        position: absolute;
        top: 0;
        left: 0;
        background-color: $color-blue-main;
        transform: translate(-50%, -50%);
        height: 7rem;
        width: 7rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        &--blue-dark{
            background-color: $color-blue-dark;
        }


        &--svg{
            color:$color-white;
            
        }
    }
}

</style>