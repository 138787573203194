<template>
  <section :id="data.id" class="container" :class="data.color ? 'container__'+data.color : ''">
      <div v-if="links" class="container__link container__link--up"></div>
      <div class="container__content">
          <div class="container__title" v-if="data.title">
              <h2>
                  {{data.title}}
              </h2>
              <h3 v-if="data.subtitle">
                  {{data.subtitle}}
              </h3>
          </div>
          <slot/>
      </div>
  </section>
</template>

<script>
export default {
    props:['data']
}
</script>

<style lang="scss" scoped>
    @import '@/style/main';
    .container{
       position: relative;
        
        &__content{
             max-width: $page-width;
            margin: 0 auto;
            padding: 6rem 0;
            display: flex;
            flex-direction: column;
            align-items: center;

            @include respond(tab-land){
                width: 95%;
            }
            @include respond(phone){
                width: 92%;
            }
            @include respond(large-desktop){
                max-width: $page-width-large;
            }

        }

        &__title{
            text-align: center;
            margin-bottom: 4rem;

            & h2{
                text-transform: uppercase;
                font-weight: 700;
                font-size: 2.4rem;
                letter-spacing: .2rem;
                margin-bottom: 1.4rem;
            }

            & h3{
                font-weight: 300;
                font-size: 1.8rem;
                letter-spacing: .1rem;
                margin-bottom: 2rem;
            }
        }

        &__blue-dark{
            background-color: $color-blue-dark;
            color:$color-white;
        }

        &__blue-light{
            background-color: $color-bck-blue-light;
        }

        &__blue{
            background-color: $color-blue-main;
            color: $color-white;
        }

        &__link{
            position: absolute;
            left: 50%;
            width: 2px;
            height: 4rem;
            background-color: $color-text;
            z-index: 5;

            &--up{
                top: 0;
                transform: translateY(-50%);
            }
        }
    }
</style>